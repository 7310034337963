import pattern from './pattern'

/**
 * Verifies if the given value is a number.
 * It can be a floar positive or negative
 * @param {String|Number} value The string to be validated
 */
export default function(value) {
  return pattern(value, /^[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\d]+$/)
}
