import pattern from './pattern'

/**
 * Verifies if the given value has at least one upper case character
 * optimized for latin characters only
 * @param {String|Number} value The string to be validated
 */
export default function(value) {
  return pattern(value, /[A-Z\u00C0-\u00D6]+/)
}
