import required from './required'
import min from './min'
import isDefined from './is-defined'
import max from './max'
import minLength from './min-length'
import { maxLength } from '.'
import { email } from '.'
import { pattern } from '.'

export default class FieldRules {
  constructor() {
    /**
     * @type {Function[]}
     */
    this.rules = []
  }

  required() {
    this.rules.push(value => {
      return required(value) || 'El campo es requerido'
    })
    return this
  }
  /**
   *
   * @param {Number} rule
   */
  min(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        min(value, rule) ||
        'El valor debe ser mayor o igual ' + rule
      )
    })
    return this
  }
  /**
   *
   * @param {Number} rule
   */
  max(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        max(value, rule) ||
        'El valor debe ser menor o igual a ' + rule
      )
    })
    return this
  }

  /**
   *
   * @param {Number} rule
   */
  minLength(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        minLength(value, rule) ||
        'El campo debe contener al menos ' + rule + ' caracteres'
      )
    })
    return this
  }

  /**
   *
   * @param {Number} rule
   */
  maxLength(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        maxLength(value, rule) ||
        'El campo debe contener máximo ' + rule + ' caracteres'
      )
    })
    return this
  }

  /***
   *
   *
   */
  email() {
    this.rules.push(value => {
      return (
        !isDefined(value) || email(value) || 'Correo electrónico incorrecto'
      )
    })
    return this
  }

  /**
   *
   * @param {regex} rule
   */
  pattern(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        pattern(value, rule) ||
        'La contraseña debe contener una letra mayúscula, una letra minúscula, un dígito y un caracter especial (#?!@$%^&*-) por lo menos.'
      )
    })
    return this
  }

  /**
   *
   * @param {regex} rule
   */
  patternExclude(rule) {
    this.rules.push(value => {
      return (
        !isDefined(value) ||
        pattern(value, rule) ||
        'Correo no válido, intenté con otro.'
      )
    })
    return this
  }

  get() {
    return this.rules
  }
}
