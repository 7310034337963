import pattern from './pattern'

/**
 * Verifies if the given value is a number.
 * It can be a floar positive or negative
 * @param {String|Number} value The string to be validated
 */
export default function(value) {
  return pattern(value, /^-?\d+(\.\d+)?$/)
}
