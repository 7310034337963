/**
 * Returns if the value is greater or equal to the max value
 * @param {Number|String|Date} value
 * @param {Number|String|Date} min
 */
export default function(value, min) {
  if (value instanceof Date && min instanceof Date) {
    return value.getTime() >= min.getTime()
  }
  return value >= min
}
