import pattern from './pattern'

/**
 * Verifies if the given value has at least one lower case character
 * @param {String|Number} value The string to be validated
 */
export default function(value) {
  return pattern(
    value,
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}
