/**
 * Returns if the value is lower or equal to the max value
 * @param {Number|String|Date} value
 * @param {Number|String|Date} max
 */
export default function(value, max) {
  if (value instanceof Date) {
    return value.getTime() <= max.getTime()
  }
  return !!value && value <= max
}
