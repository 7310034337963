import isNumber from './is-number'
import isDefined from './is-defined'

/**
 * Returns if the value length is lower or equal to the max value
 * @param {Number|String} value
 * @param {Number|String} max
 */
export default function(value, max) {
  if (!isNumber(max)) throw new Error('Max has to be a number')
  return isDefined(value) && value.length <= max
}
