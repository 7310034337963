import isDefined from './is-defined'

/**
 * Verifies if a value fulfills a regular expression
 * @param {String|Number} value The string to be validated
 * @param {String|RegExp} regexp The regular expression to be used
 * @returns {Boolean}
 */
export default function(value, regexp) {
  const rEx = new RegExp(regexp)
  return isDefined(value) && rEx.test(value.toString())
}
