//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModelWant from 'src/models/model-contact.js'
import VueRecaptcha from 'vue-recaptcha'
export default {
  name: 'page-contacto',
  components: {
    VueRecaptcha
  },
  data() {
    return {
      model: new ModelWant(),
      showForm: true,
      options: null,
      confirm: false,
      nameForm: '',
      flagNameForm: false,
      captchaKey: null
    }
  },
  computed: {
    departments() {
      let items = this.$store.getters['departments/items']
      return items.map(item => {
        return {
          label: item.label,
          value: item.name
        }
      })
    },
    carModels() {
      let items = this.$store.getters['carmodels/items']
      let models = items.map(item => {
        return {
          label: item.title.toUpperCase(),
          value: item.name
        }
      })
      models.splice(0, 0, {
        label: 'JIMNY',
        value: 'JIMNY'
      })
      return models
    },
    dealerships() {
      let items = this.$store.getters['dealerships/items']
      let x = items.map(item => item.items)
      //x = x.flat()
      x = x.reduce((acc, val) => acc.concat(val), [])
      let dealerships = x.map(item => {
        let clone = Object.assign({}, item)
        clone.id = clone.id.toString()
        return clone
      })
      return dealerships
    }
  },
  mounted() {
    this.options = this.dealerships
    if (this.$route.name == 'contacto') {
      this.nameForm = 'Atención al cliente'
      this.flagNameForm = false
      this.model.type = 'CONTACT'
    } else {
      this.nameForm = 'Comentarios y otros'
      this.flagNameForm = true
      this.model.type = 'DUDES'
    }
  },
  methods: {
    onSubmit() {
      if (this.flagNameForm) {
        this.model.dealershipId = parseInt(this.model.dealershipId)
      }
      if (this.captchaKey) {
        this.$q.loading.show()
        this.model.token = this.captchaKey
        this.$axios
          .post('/contacts', this.model)
          .then(() => {
            this.confirm = true
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.$q.loading.hide()
            if (this.flagNameForm) {
              this.model.dealershipId = this.model.dealershipId.toString()
            }
          })
      } else {
        this.$q.notify({
          message: 'Por favor, comprueba que eres un humano.',
          color: 'red-5'
        })
      }
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.options = this.dealerships
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        this.options = this.dealerships.filter(
          v =>
            v.name.toLowerCase().indexOf(needle) > -1 ||
            v.address.toLowerCase().indexOf(needle) > -1
        )
      })
    },
    closeConfirm() {
      this.confirm = false
      this.$router.go()
    },
    verify(response) {
      this.captchaKey = response
    }
  },
  async preFetch({ store }) {
    try {
      await store.dispatch('departments/query')
      await store.dispatch('dealerships/query')
      return true
    } catch (e) {
      return false
    }
  }
}
