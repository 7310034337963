import pattern from './pattern'
import isDefined from './is-defined'

export default function(value) {
  if (isDefined(value)) {
    return pattern(value.trim(), /.+/)
  } else {
    return false
  }
}
